import React, { FC, useContext } from "react";
import Logo from "../Logo";
import MenuButton from "./MenuButton";
import { AppContext } from "../../hoc/ContextProvider";
import {
  CatalogSectionButton,
  HowUseSectionButton,
  CalculatorSectionButton,
  ContactsSectionButton,
} from "./SectionButtons";

const Header: FC = () => {
  const [state] = useContext(AppContext);

  return (
    <header>
      <div className="HeaderContent PlatformOffset">
        {!state.isMobile ? (
          <>
            <CatalogSectionButton />
            <HowUseSectionButton />
            <Logo />
            <CalculatorSectionButton />
            <ContactsSectionButton />
          </>
        ) : (
          <>
            <Logo />
            <MenuButton />
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
