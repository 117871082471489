import { FC, useContext } from "react";
import { AppContext } from "../hoc/ContextProvider";

const Loading: FC = () => {
  const [state] = useContext(AppContext);

  return (
    <>
      {state.loading && (
        <div className="Loading">
          <div className="Loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
