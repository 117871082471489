import { FC } from "react";
import CatalogItemModel from "../../models/catalog-item-model";

interface Props {
  model: CatalogItemModel;
}

const CatalogItemInfo: FC<Props> = ({ model }) => {
  return (
    <div className="CatalogItemInfo">
      <p className="BodyMedium">{`Тип покрытия: ${model.coatingType}`}</p>
      <p className="TitleMedium">{model.title}</p>
      <p className="BodyMedium">{`Толщина: ${model.thickness}`}</p>
      {model.colors ? (
        <p className="BodyMedium">{`Цвета: ${model.colors!}`}</p>
      ) : (
        <div className="ColorsPlaceholder" />
      )}
    </div>
  );
};

export default CatalogItemInfo;
