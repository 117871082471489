import { useCallback, useRef } from "react";

type fun = (...args: any[]) => any;

const useEvent = <T extends fun>(handler: T): T => {
  const ref = useRef(handler);
  ref.current = handler;
  //@ts-ignore
  return useCallback((...args) => {
    const fn = ref.current;
    return fn(...args);
  }, []);
};

export default useEvent;
