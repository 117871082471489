import { FC, useCallback, useContext, useEffect, useState } from "react";
import FilledButton from "../FilledButton";
import { AppContext } from "../../hoc/ContextProvider";
import { SHOW_CONTACT_TO_CALCULATE_COST_MODALIZE } from "../../reducer/actions";
import { SEAMLESS_COATING } from "../../data/catalog-items";
import removeZerosAfterPoint from "../../utils/remove-zeros-after-point";
import addSpacesInNumber from "../../utils/add-spaces-in-number";
import useEvent from "../../hooks/useEvent";
import Field from "../Field";
import { numberValidate } from "../../utils/validators";

const RUBBER_CRUMB_COEFFICIENT = 8;
const POLYURETHANE_CRUMB_COEFFICIENT = 2;
const DYE_CRUMB_COEFFICIENT = 0.5;
const TURPENTINE_COEFFICIENT = 0.3;

const NUMBER_REGEXP = /^\d{1,10}$/;

const SeamlessCoatingCalculator: FC = () => {
  const [, dispatch] = useContext(AppContext);
  const [area, setArea] = useState("0");
  const [rubberCrumbKg, setRubberCrumbKg] = useState("0");
  const [polyurethaneGlueKg, setPolyurethaneGlueKg] = useState("0");
  const [dyeKg, setDyeKg] = useState("0");
  const [turpentineKg, setTurpentineKg] = useState("0");
  const [price, setPrice] = useState("0");
  const [itemPriceModel, setItemPriceModel] = useState(
    SEAMLESS_COATING.prices[0]
  );
  const [areaInvalidText, setAreaInvalidText] = useState<string>();

  useEffect(() => {
    const areaNum = +area;

    const areaXThickness = areaNum * (itemPriceModel.thickness / 10);
    const convertToNumString = (num: number): string => {
      return removeZerosAfterPoint(num.toFixed(2));
    };

    setRubberCrumbKg(
      convertToNumString(areaXThickness * RUBBER_CRUMB_COEFFICIENT)
    );
    setPolyurethaneGlueKg(
      convertToNumString(areaXThickness * POLYURETHANE_CRUMB_COEFFICIENT)
    );
    setDyeKg(convertToNumString(areaXThickness * DYE_CRUMB_COEFFICIENT));
    setTurpentineKg(
      convertToNumString(areaXThickness * TURPENTINE_COEFFICIENT)
    );
    setPrice(
      addSpacesInNumber(
        convertToNumString(areaNum * itemPriceModel.minPricePerSquareMeter)
      )
    );
  }, [area, itemPriceModel]);

  const changeArea = useCallback((event) => {
    var newValue: string = event.target.value;

    while (newValue.startsWith("0")) {
      newValue = newValue.replace("0", "");
    }

    if (newValue === "") {
      setArea("0");
    } else if (NUMBER_REGEXP.test(newValue)) {
      setArea(newValue);
    }
  }, []);

  const onChangeSelect = useCallback((event) => {
    var newValue: number = event.target.value;
    const searchedValue = SEAMLESS_COATING.prices.filter((v) => {
      return v.thickness == newValue;
    })[0];
    setItemPriceModel(searchedValue);
  }, []);

  const showContactToCalculateCostModalize = useEvent(() => {
    const isAreaValid = () => {
      const invalidText = numberValidate(area);
      setAreaInvalidText(invalidText);
      return !invalidText;
    };

    if (isAreaValid())
      dispatch({
        type: SHOW_CONTACT_TO_CALCULATE_COST_MODALIZE,
        payload: {
          area: +area,
          thickness: +itemPriceModel.thickness,
        },
      });
      setArea("0")
  });

  return (
    <div className="SeamlessCoatingCalculator Form">
      <Field label={"Площадь в м²"} invalidText={areaInvalidText}>
        <input type="text" value={area} onChange={changeArea} />
      </Field>
      <div className="SeamlessElements">
        <p className="TitleSmall">{`${rubberCrumbKg} кг резиновой крошки`}</p>
        <p className="TitleSmall">
          {`${polyurethaneGlueKg} кг полиуретанового клея`}
        </p>
        <p className="TitleSmall">{`${dyeKg} кг красителя`}</p>
        <p className="TitleSmall">{`${turpentineKg} кг скипидара`}</p>
      </div>
      <Field label={"Толщина в мм"}>
        <select onChange={onChangeSelect}>
          <SelectItems />
        </select>
      </Field>
      <p className="TitleLarge">
        Итог: <b className="Bold">{`${price} руб`}</b>
      </p>
      <div className="WarningInformation">
        <p className="TitleSmall">
          *Данный расчет является предварительным, детальную стоимость вы
          узнаете после звонка специалиста
        </p>
        <FilledButton
          label="Узнать стоимость"
          onClick={showContactToCalculateCostModalize}
        />
      </div>
    </div>
  );
};

export default SeamlessCoatingCalculator;

const SelectItems: FC = () => {
  return (
    <>
      {SEAMLESS_COATING.prices.map((item) => (
        <option
          value={item.thickness}
          key={item.thickness}
        >{`${item.thickness} мм`}</option>
      ))}
    </>
  );
};
