export type Action = {
  type: string;
  payload?: any;
};

export const CHANGE_IS_MOBILE = "CHANGE_IS_MOBILE";
export const SELECT_CATALOG_ITEM = "SELECT_CATALOG_ITEM";
export const UNSELECT_CATALOG_ITEM = "UNSELECT_CATALOG_ITEM";
export const SHOW_CONTACT_TO_ORDER_MODALIZE = "SHOW_CONTACT_TO_ORDER_MODALIZE";
export const HIDE_CONTACT_TO_ORDER_MODALIZE = "HIDE_CONTACT_TO_ORDER_MODALIZE";
export const SHOW_CONTACT_TO_CALCULATE_COST_MODALIZE =
  "SHOW_CONTACT_TO_CALCULATE_COST_MODALIZE";
export const HIDE_CONTACT_TO_CALCULATE_COST_MODALIZE =
  "HIDE_CONTACT_TO_CALCULATE_COST_MODALIZE";
export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR"
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";
export const SHOW_MOBILE_MENU = "SHOW_MOBILE_MENU";
export const HIDE_MOBILE_MENU = "HIDE_MOBILE_MENU";
