import { FC } from "react";
import SeamlessCoatingCalculator from "./SeamlessCoatingCalculator";
import BlockCardWrapper from "../BlockCardWrapper";

const SeamlessCoatingCalculatorBlock: FC = () => {
  return (
    <BlockCardWrapper
      className="SeamlessCoatingCalculatorBlock"
      blockTitle="Калькулятор бесшовного покрытия"
      id="калькулятор"
    >
      <SeamlessCoatingCalculator />
    </BlockCardWrapper>
  );
};

export default SeamlessCoatingCalculatorBlock;
