import {
  Action,
  CHANGE_IS_MOBILE,
  HIDE_CONTACT_TO_CALCULATE_COST_MODALIZE,
  HIDE_CONTACT_TO_ORDER_MODALIZE,
  START_LOADING,
  SELECT_CATALOG_ITEM,
  SHOW_CONTACT_TO_CALCULATE_COST_MODALIZE,
  SHOW_CONTACT_TO_ORDER_MODALIZE,
  UNSELECT_CATALOG_ITEM,
  FINISH_LOADING,
  SHOW_SNACKBAR,
  HIDE_SNACKBAR,
  SHOW_MOBILE_MENU,
  HIDE_MOBILE_MENU,
} from "./actions";
import { State } from "./state";

const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case CHANGE_IS_MOBILE:
      console.log(action.type, action.payload);
      return {
        ...state,
        isMobile: action.payload,
      };
    case SELECT_CATALOG_ITEM:
      console.log(action.type, action.payload);
      return {
        ...state,
        catalogItem: action.payload,
      };
    case UNSELECT_CATALOG_ITEM:
      console.log(action.type);
      return {
        ...state,
        catalogItem: undefined,
      };
    case SHOW_CONTACT_TO_ORDER_MODALIZE:
      console.log(action.type);
      return {
        ...state,
        isContactToOrderModalizeShowed: true,
      };
    case HIDE_CONTACT_TO_ORDER_MODALIZE:
      console.log(action.type);
      return {
        ...state,
        isContactToOrderModalizeShowed: false,
      };
    case SHOW_CONTACT_TO_CALCULATE_COST_MODALIZE:
      console.log(action.type, action.payload);
      return {
        ...state,
        calculateCostModalizeData: action.payload,
      };
    case HIDE_CONTACT_TO_CALCULATE_COST_MODALIZE:
      console.log(action.type);
      return {
        ...state,
        calculateCostModalizeData: undefined,
      };
    case START_LOADING:
      console.log(action.type);
      return {
        ...state,
        loading: true,
      };
    case FINISH_LOADING:
      console.log(action.type);
      return {
        ...state,
        loading: false,
      };
    case SHOW_SNACKBAR:
      console.log(action.type, action.payload);
      return {
        ...state,
        snackbarText: action.payload,
      };
    case HIDE_SNACKBAR:
      console.log(action.type);
      return {
        ...state,
        snackbarText: undefined,
      };
    case SHOW_MOBILE_MENU:
      console.log(action.type);
      return {
        ...state,
        isMobileMenuShowed: true,
      };
    case HIDE_MOBILE_MENU:
      console.log(action.type);
      return {
        ...state,
        isMobileMenuShowed: false,
      };
  }
  return state;
};

export default stateReducer;
