import { FC } from "react";

interface Props {
  label: string;
  invalidText?: string;
}

const Field: FC<Props> = ({ label, children, invalidText }) => {
  return (
    <div className={`Field ${!!invalidText ? "Invalid" : ""}`}>
      <p className="HeadlineMedium">{label}</p>
      {children}
      {!!invalidText && <p className="HeadlineSmall">{invalidText}</p>}
    </div>
  );
};

export default Field;
