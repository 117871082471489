import { FC } from "react";
import HowUseItemModel from "../../models/how-use-item-model";

interface Props {
  model: HowUseItemModel;
  isPrimaryColor?: boolean;
  isMiddle?: boolean;
}

const HowUseItem: FC<Props> = (props) => {
  const isPrimaryColor = props.isPrimaryColor ?? false;
  const isMiddle = props.isMiddle ?? false;
  const classes = `HowUseItem ${isPrimaryColor ? "Primary" : ""} ${
    isMiddle ? "TopOffset" : ""
  }`;
  return (
    <div className={classes}>
      <img className="Icon" src={props.model.icon} height={50} />
      <p className="TitleMedium">{props.model.label}</p>
    </div>
  );
};

export default HowUseItem;
