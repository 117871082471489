import { FC } from "react";
import APP_SETTINGS from "../../data/app-settings";
import TouchableOpacity from "../TouchableOpacity";

const ContactInfo: FC = () => {
  return (
    <div className="ContactInfo">
      <div className="PlatformOffset">
        <div className="Contacts">
          <p className="TitleMedium">
            Телефон:
            <TouchableOpacity>
              <a
                className="TitleMedium Link"
                href={`tel://${APP_SETTINGS.phoneNumber}`}
              >
                {APP_SETTINGS.phoneNumber}
              </a>
            </TouchableOpacity>
          </p>
          <p className="TitleMedium">
            Почта:
            <TouchableOpacity>
              <a
                className="TitleMedium Link"
                href={`mailto://${APP_SETTINGS.emailAddress}`}
              >
                {APP_SETTINGS.emailAddress}
              </a>
            </TouchableOpacity>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
