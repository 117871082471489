import React, { FC} from "react";
import StartBlock from "./components/blocks/StartBlock";
import Header from "./components/header/Header";
import CatalogBlock from "./components/catalog-block/CatalogBlock";
import WarningBlock from "./components/blocks/WarningBlock";
import HowUseBlock from "./components/how-use-block/HowUseBlock";
import SeamlessCoatingCalculatorBlock from "./components/seamless-coating-calculator-block/SeamlessCoatingCalculatorBlock";
import FAQBlock from "./components/faq-block/FAQBlock";
import QuestionBlock from "./components/question-block/QuestionBlock";
import Footer from "./components/footer/Footer";
import ContactInfo from "./components/footer/ContactInfo";
import CatalogItemModalize from "./components/modalizes/CatalogItemModalize";
import ContactToOrderModalize from "./components/modalizes/ContactToOrderModalize";
import ContactToCalculateCostModalize from "./components/modalizes/ContactToCalculateCostModalize";
import Loading from "./components/Loading";
import Snackbar from "./components/Snackbar";
import MobileMenu from "./components/header/MobileMenu";

interface Props {}

const Home: FC<Props> = () => {
  return (
    <>
      <div className="Home">
        <Header />
        <div className="HomeContent">
          <StartBlock />
          <CatalogBlock />
          <WarningBlock />
          <HowUseBlock />
          <SeamlessCoatingCalculatorBlock />
          <FAQBlock />
          <QuestionBlock />
          <Footer />
          <ContactInfo />
        </div>
      </div>
      <MobileMenu />
      <CatalogItemModalize />
      <ContactToOrderModalize />
      <ContactToCalculateCostModalize />
      <Snackbar />
      <Loading />
    </>
  );
};

export default React.memo(Home);
