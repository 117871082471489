import React, { FC } from "react";
import HowUseItem from "./HowUseItem";
import HOW_USE_ITEMS from "../../data/how-use-items";
import BlockCardWrapper from "../BlockCardWrapper";

const HowUseBlock: FC = () => {
  return (
    <BlockCardWrapper
      className="HowUseBlock"
      blockTitle="Применение"
      id="применение"
    >
      <div className="HowUseBlockContent">
        <HowUseItems />
      </div>
    </BlockCardWrapper>
  );
};

export default HowUseBlock;

const HowUseItems: FC = React.memo(() => {
  return (
    <>
      {HOW_USE_ITEMS.map((model, index) => (
        <HowUseItem
          key={model.label}
          model={model}
          isPrimaryColor={index % 2 === 0}
          isMiddle={index % 3 === 1}
        />
      ))}
    </>
  );
});
