import React, { FC, useCallback, useContext } from "react";
import CatalogItemModel from "../../models/catalog-item-model";
import CatalogItemInfo from "./CatalogItemInfo";
import FilledButton from "../FilledButton";
import getImage from "../../utils/get-image";
import { AppContext } from "../../hoc/ContextProvider";
import { SELECT_CATALOG_ITEM } from "../../reducer/actions";

interface Props {
  model: CatalogItemModel;
}

const CatalogItem: FC<Props> = ({ model }) => {
  return (
    <div className="CatalogItem">
      <CatalogItemContent model={model} />
    </div>
  );
};

export default CatalogItem;

const CatalogItemContent: FC<Props> = ({ model }) => {
  const [_, dispatch] = useContext(AppContext);

  const openModalize = useCallback(() => {
    dispatch({
      type: SELECT_CATALOG_ITEM,
      payload: model,
    });
  }, [model]);

  return (
    <>
      <img
        src={getImage(model)}
        alt={model.title}
        width={260}
        className="CatalogItemImage"
      />
      <CatalogItemInfo model={model} />
      <div className="CatalogItemPriceView">
        <p className="HeadlineLarge">
          {`от ${model.minPricePerSquareMeter} руб за м²`}
        </p>
        <FilledButton label="Узнать подробнее" onClick={openModalize} />
      </div>
    </>
  );
};
