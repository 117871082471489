import CatalogItemModel from "../models/catalog-item-model";

export type State = {
  isMobile: boolean;
  catalogItem?: CatalogItemModel;
  isContactToOrderModalizeShowed: boolean;
  calculateCostModalizeData?: {
    area: number;
    thickness: number;
  };
  loading: boolean;
  snackbarText?: string;
  isMobileMenuShowed: boolean;
};

export const initialState: State = {
  isMobile: false,
  isContactToOrderModalizeShowed: false,
  loading: false,
  isMobileMenuShowed: false,
};
