import { FC } from "react";
import TouchableOpacity from "./TouchableOpacity";

type Props = {
  isSecondary?: boolean;
  label: string;
  onClick?: () => void;
};

const FilledButton: FC<Props> = (props) => {
  const isSecondary = props.isSecondary ?? false;

  return (
    <TouchableOpacity
      onClick={props.onClick}
      className={`FilledButton ${isSecondary ? "SecondaryButton" : ""}`}
    >
      <p className="HeadlineSmall">{props.label}</p>
    </TouchableOpacity>
  );
};

export default FilledButton;
