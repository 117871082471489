import { FC, PropsWithChildren, useEffect } from "react";
import TouchableOpacity from "../TouchableOpacity";
import CloseIcon from "../../../assets/icons/close.png";

interface Props {
  onClose: () => void;
}

const ModalWrapper: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onClose } = props;

  useEffect(() => {
    const closeOnEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", closeOnEsc);
    return () => {
      window.removeEventListener("keydown", closeOnEsc);
    };
  });

  return (
    <>
      <div className="ModalizeContainer">
        <TouchableOpacity
          onClick={onClose}
          notUseOpacity
          className="ModalizeBackground"
        />

        <div className="Modalize">
          <div className="PlatformOffset">
            <div className="ModalizeBackgroundOffset">
              <div className="ModalizeCard">
                <div className="ModalizeContent">
                  <TouchableOpacity
                    className="ModalizeCloseButton"
                    onClick={onClose}
                  >
                    <img src={CloseIcon} alt="X" />
                  </TouchableOpacity>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalWrapper;
