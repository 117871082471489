import React, { FC, useCallback, useState } from "react";
import FAQItemModel from "../../models/faq-item-model";
import ArrowBottom from "../../../assets/icons/arrow-bottom.svg";
import TouchableOpacity from "../TouchableOpacity";

interface Props {
  model: FAQItemModel;
}

const FAQItem: FC<Props> = ({ model }) => {
  const [isOpen, setIsOpen] = useState(false);

  const changeIsOpen = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  return (
    <TouchableOpacity
      onClick={changeIsOpen}
      className={`FAQItem ${isOpen ? "IsOpen" : ""}`}
    >
      <div className="FAQItemHeader">
        <p className="TitleMedium">{model.question}</p>
        <div className="FAQItemOpenButton">
          <img src={ArrowBottom} width={36} />
        </div>
      </div>
      {isOpen && (
        <div className="FAQItemContent">
          <p className="TitleSmall">{model.answer}</p>
        </div>
      )}
    </TouchableOpacity>
  );
};

export default FAQItem;
