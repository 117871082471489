import { FC } from "react";
import TouchableOpacity from "../TouchableOpacity";

interface Props {
  onPress: () => void;
}

const CloseButton: FC<Props> = ({ onPress }) => {
  return (
    <TouchableOpacity className="CloseButton" onClick={onPress}>
      <div className="CloseIcon" />
    </TouchableOpacity>
  );
};

export default CloseButton;
