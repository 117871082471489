import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {  AppProvider } from "./hoc/ContextProvider";
import PlatformDetector from "./hoc/PlatformDetector";
import Home from "./Home";

const App: FC = () => {
  return (
    <AppProvider>
      <PlatformDetector>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </PlatformDetector>
    </AppProvider>
  );
};

export default App;
