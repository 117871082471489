import { FC, useCallback, useContext } from "react";
import BlockCardWrapper from "../BlockCardWrapper";
import TouchableOpacity from "../TouchableOpacity";
import { AppContext } from "../../hoc/ContextProvider";
import { SHOW_CONTACT_TO_ORDER_MODALIZE } from "../../reducer/actions";

const WarningBlock: FC = () => {
  const [, dispatch] = useContext(AppContext);

  const showContactModal = useCallback(() => {
    dispatch({
      type: SHOW_CONTACT_TO_ORDER_MODALIZE,
    });
  }, [dispatch]);

  return (
    <BlockCardWrapper className="WarningBlock" blockTitle="Внимание">
      <p className="TitleMedium">
        {
          "Все цены, указанные выше, предварительны. Для уточнения точной цены, "
        }
        <TouchableOpacity onClick={showContactModal}>
          <p className="TitleMedium Link">оставьте заявку</p>
        </TouchableOpacity>
        , и специалист свяжется с вами в ближайшее время
      </p>
    </BlockCardWrapper>
  );
};

export default WarningBlock;
