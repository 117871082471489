import { FC } from "react";
import FAQ_ITEMS from "../../data/faq-items";
import React from "react";
import FAQItem from "./FAQItem";
import BlockCardWrapper from "../BlockCardWrapper";

const FAQBlock: FC = () => {
  return (
    <BlockCardWrapper
      className="FAQBlock"
      blockTitle="Часто задаваемые вопросы"
    >
      <div className="FAQBlockContent">
        <FAQItems />
      </div>
    </BlockCardWrapper>
  );
};

export default FAQBlock;

const FAQItems: FC = React.memo(() => {
  return (
    <>
      {FAQ_ITEMS.map((model) => (
        <FAQItem key={model.question} model={model} />
      ))}
    </>
  );
});
