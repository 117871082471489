import { FC, useContext } from "react";
import { AppContext } from "../hoc/ContextProvider";
import LogoImage from "../../assets/icons/logo.png";
import LogoLightImage from "../../assets/icons/logo-light.png";
import TouchableOpacity from "./TouchableOpacity";
import { HashLink } from "react-router-hash-link";

interface Props {
  isLightLogo?: boolean;
  onPress?: () => void;
}

const Logo: FC<Props> = ({ isLightLogo, onPress }) => {
  const [state] = useContext(AppContext);

  const size = !state.isMobile ? 32 : 25;

  return (
    <TouchableOpacity
      className={`Logo ${isLightLogo ? "LightLogo" : ""}`}
      onClick={onPress}
    >
      <HashLink smooth to={`#`}>
        <img
          src={isLightLogo ? LogoLightImage : LogoImage}
          alt="Логотип"
          width={size}
          height={size}
        />
        <h3 className="DisplaySmall">ЛюксПлит</h3>
      </HashLink>
    </TouchableOpacity>
  );
};

export default Logo;
