const addSpacesInNumber = (num: string): string => {
  return num
    .split("")
    .reverse()
    .map((v, index) => `${v}${index !== 0 && index % 3 === 0 ? " " : ""}`)
    .reverse()
    .join("");
};

export default addSpacesInNumber;
