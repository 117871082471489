import sendEmail from "../api/send-email";

enum RequestTitle {
  requestForContact = "Заявка для связи",
  requestForCalculateCost = "Заявка для расчета стоимости бесшовного покрытия",
  question = "Вопрос",
}

export const sendContactToCalculateCost = (
  phoneNumber: string,
  personName: string,
  area: number,
  thickness: number
) => {
  const title = `${RequestTitle.requestForCalculateCost} ${phoneNumber}`;
  const text = `Добрый день, могли бы вы со мной связаться для расчета стоимости бесшовного покрытия ${area} м² толщиной ${thickness} мм\n${phoneNumber} ${personName}`;
  return sendEmail(title, text);
};

export const sendContactToOrder = (phoneNumber: string, personName: string) => {
  const title = `${RequestTitle.requestForContact} ${phoneNumber}`;
  const text = `Добрый день, могли бы вы со мной связаться\n${phoneNumber} ${personName}`;
  return sendEmail(title, text);
};

export const sendQuestion = (
  phoneNumber: string,
  personName: string,
  question: string
) => {
  const title = `${RequestTitle.question} ${phoneNumber}`;
  const text = `Добрый день, могли бы вы со мной связаться по вопросу "${question}"\n${phoneNumber} ${personName}`;
  return sendEmail(title, text);
};
