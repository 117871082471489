import CatalogItemImageModel from "../models/catalog-item-image-model";
import CatalogItemModel from "../models/catalog-item-model";
import CoatingType from "../models/coating-type";


const tale500x500QuantityInSquareMeter = 4;

export const TALE_500_X_500 = new CatalogItemModel({
  title: "Резиновая плитка",
  size: {
    width: 500,
    length: 500,
  },
  image: CatalogItemImageModel.tile500,
  coatingType: CoatingType.tile,
  colors: "Терракотовый, черный, зеленый",
  useOnCoating: "Использование на любых покрытиях (песок, асфальт и тд.)",
  quantityPerSquareMeter: tale500x500QuantityInSquareMeter,
  usetime: "не менее 10 лет",
  kit: "В комплект входят соединительные втулки (для 40 и 50 мм)",
  prices: [
    {
      thickness: 20,
      minPricePerSquareMeter: tale500x500QuantityInSquareMeter * 525,
    },
    {
      thickness: 30,
      minPricePerSquareMeter: tale500x500QuantityInSquareMeter * 600,
    },
    {
      thickness: 40,
      minPricePerSquareMeter: tale500x500QuantityInSquareMeter * 650,
    },
    {
      thickness: 50,
      minPricePerSquareMeter: tale500x500QuantityInSquareMeter * 725,
    },
  ],
});

export const TALE_1000_X_1000 = new CatalogItemModel({
  title: "Резиновая плитка",
  size: {
    width: 1000,
    length: 1000,
  },
  image: CatalogItemImageModel.tile1000,
  coatingType: CoatingType.tile,
  colors: "Терракотовый, черный, зеленый",
  useOnCoating: "Использование на любых покрытиях (песок, асфальт и тд.)",
  quantityPerSquareMeter: 1,
  usetime: "не менее 10 лет",
  kit: "В комплект входят соединительные втулки (для 40 мм)",
  prices: [
    { thickness: 20, minPricePerSquareMeter: 2100 },
    { thickness: 30, minPricePerSquareMeter: 2400 },
    { thickness: 40, minPricePerSquareMeter: 2900 },
  ],
});

export const SEAMLESS_COATING = new CatalogItemModel({
  title: "Бесшовное покрытие",
  image: CatalogItemImageModel.seamless,
  coatingType: CoatingType.seamless,
  composition: "резиновая крошка, связующее, краситель",
  useOnCoating: "Укладка только на твердое основание",
  usetime: "не менее 3-х лет",
  prices: [
    { thickness: 10, minPricePerSquareMeter: 1800 },
    { thickness: 20, minPricePerSquareMeter: 2800 },
    { thickness: 30, minPricePerSquareMeter: 3800 },
    { thickness: 40, minPricePerSquareMeter: 4800 },
  ],
  isNeedTable: false,
});


export const CATALOG_ITEMS: CatalogItemModel[] = [
  TALE_500_X_500,
  TALE_1000_X_1000,
  SEAMLESS_COATING,
];

