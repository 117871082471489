import React, { FC, PropsWithChildren, useContext, useEffect } from "react";
import { AppContext } from "./ContextProvider";
import { CHANGE_IS_MOBILE } from "../reducer/actions";
import useEvent from "../hooks/useEvent";

const PlatformDetector: FC<PropsWithChildren<{}>> = (props) => {
  const [state, dispatch] = useContext(AppContext);

  const handleWindowSizeChange = useEvent(() => {
    const isMobile = window.innerWidth < 1140;

    if (state.isMobile !== isMobile) {
      dispatch({
        type: CHANGE_IS_MOBILE,
        payload: isMobile,
      });

      if (isMobile) {
        document.body.classList.add("Mobile");
      } else {
        document.body.classList.remove("Mobile");
      }
    }
  });

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  return <>{props.children}</>;
};

export default PlatformDetector;
