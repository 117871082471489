import FAQItemModel from "../models/faq-item-model";

const FAQ_ITEMS:FAQItemModel[] = [
  {
    question: "Есть ли гарантия на резиновую плитку?",
    answer:
      "При соблюдении эксплуатационных требований и рекомендаций - гарантия до 1 года.",
  },
  {
    question: "Какую температуру может выдержать резиновая плитка?",
    answer:
      "-40˚ C- +70 ˚C. Плитка максимально адаптирована к условиям российского климата – будь то Сочи, или Якутия.",
  },
  {
    question: "Какой расход полиуретанового клея?",
    answer:
      "Средний расход клея - 250 г/кв.м. То есть вам понадобится около 750 г. на площадку 3 кв.м.",
  },
  {
    question: "Возможно ли оформить заказ на плитку в ином цвете?",
    answer:
      "Конечно, мы можем предоставить вам плитку в любом цвете. Мы специализируемся на изготовлении плитки по индивидуальному заказу, поэтому вы можете выбрать любой цвет, который наиболее соответствует вашим потребностям и предпочтениям.",
  },
  {
    question: "Есть ли запах резины на детских площадках в жару?",
    answer:
      "На улице, через 3-4 дня после укладки, запах полностью исчезает. В помещении - немного дольше, порядка 5-7 дней до полного исчезновения запаха.",
  },
];

export default FAQ_ITEMS;