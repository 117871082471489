import Tile500Image from "../../assets/images/tile500.png";
import Tile1000Image from "../../assets/images/tile1000.png";
import Seamless from "../../assets/images/seamless.png";
import CatalogItemImageModel from "../models/catalog-item-image-model";
import CatalogItemModel from "../models/catalog-item-model";

const getImage = (model: CatalogItemModel) => {
  switch (model.image) {
    case CatalogItemImageModel.tile1000:
      return Tile1000Image;
    case CatalogItemImageModel.tile500:
      return Tile500Image;
    case CatalogItemImageModel.seamless:
      return Seamless;
  }
  return Tile1000Image;
};

export default getImage;
