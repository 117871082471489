import React, { FC } from "react";
import { CATALOG_ITEMS } from "../../data/catalog-items";
import CatalogItem from "./CatalogItem";
import BlockCardWrapper from "../BlockCardWrapper";

const CatalogBlock: FC = () => {
  return (
    <BlockCardWrapper
      className="CatalogBlock"
      blockTitle="Каталог"
      id="каталог"
    >
      <CatalogsItemsView />
    </BlockCardWrapper>
  );
};

export default CatalogBlock;

const CatalogsItemsView: FC = React.memo(() => {
  return (
    <div className="CatalogsItemsView">
      {CATALOG_ITEMS.map((item) => (
        <CatalogItem model={item} key={item.title} />
      ))}
    </div>
  );
});
