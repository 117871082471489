import DumbbellIcon from "../../assets/icons/dumbbell.png";
import IceSkatingIcon from "../../assets/icons/ice-skating.png";
import ParkIcon from "../../assets/icons/park.png";
import PavementIcon from "../../assets/icons/pavement.png";
import PlaygroundIcon from "../../assets/icons/playground.png";
import SportsIcon from "../../assets/icons/sports.png";
import HowUseItemModel from "../models/how-use-item-model";

const HOW_USE_ITEMS: HowUseItemModel[] = [
  {
    label: "Детские площади",
    icon: PlaygroundIcon,
  },
  {
    label: "Спортивные площади",
    icon: SportsIcon,
  },
  {
    label: "Катки",
    icon: IceSkatingIcon,
  },
  {
    label: "Тренажерные и фитнес залы",
    icon: DumbbellIcon,
  },
  {
    label: "Парки и зоны отдыха",
    icon: ParkIcon,
  },
  {
    label: "Тротуары",
    icon: PavementIcon,
  },
];

export default HOW_USE_ITEMS;
