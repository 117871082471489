import { FC, useCallback, useContext } from "react";
import ModalWrapper from "./ModalWrapper";
import { AppContext } from "../../hoc/ContextProvider";
import getImage from "../../utils/get-image";
import PriceTable from "./PriceTable";
import { UNSELECT_CATALOG_ITEM } from "../../reducer/actions";

const CatalogItemModalize: FC = () => {
  const [state, dispatch] = useContext(AppContext);
  const catalogItem = state.catalogItem;

  const onClose = useCallback(() => {
    dispatch({
      type: UNSELECT_CATALOG_ITEM,
    });
  }, []);

  return (
    <>
      {!!catalogItem && (
        <ModalWrapper onClose={onClose}>
          <h3 className="DisplaySmall">{catalogItem?.title}</h3>
          {!state.isMobile ? (
            <div className="CatalogItemImageDetail">
              <img
                src={getImage(catalogItem)}
                alt={catalogItem.title}
                width={260}
              />
              <CatalogItemDetail />
            </div>
          ) : (
            <CatalogItemDetail />
          )}
          {!!catalogItem.size && <PriceTable />}
        </ModalWrapper>
      )}
    </>
  );
};

export default CatalogItemModalize;

const CatalogItemDetail: FC = () => {
  const [state] = useContext(AppContext);
  const catalogItem = state.catalogItem!;

  return (
    <div className="CatalogItemDetail">
      {!!catalogItem?.composition && (
        <p className="BodyMedium">{`Состав: ${catalogItem.composition}`}</p>
      )}
      <p className="BodyMedium">{catalogItem?.useOnCoating}</p>
      {!!catalogItem?.quantityPerSquareMeter && (
        <p className="BodyMedium">{`${catalogItem?.quantityPerSquareMeter} шт. в м²`}</p>
      )}
      <p className="BodyMedium">{`Срок эксплуатации: ${catalogItem?.usetime}`}</p>
      {!!catalogItem?.kit && <p className="BodyMedium">{catalogItem?.kit}</p>}
      <p className="BodyMedium">
        Толщина:<b>{` ${catalogItem?.thickness}`}</b>
      </p>
      {!!catalogItem?.colors && (
        <p className="BodyMedium">{`Цвета: ${catalogItem?.colors}`}</p>
      )}
    </div>
  );
};
