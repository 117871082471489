import { ChangeEvent, FC, useState } from "react";
import FilledButton from "./FilledButton";
import useEvent from "../hooks/useEvent";
import Field from "./Field";
import { emptyValueValidate, phoneNumberValidate } from "../utils/validators";

interface Props {
  contactName: string;
  changeContactName: (event: ChangeEvent<HTMLInputElement>) => void;
  phoneNumber: string;
  changePhoneNumber: (event: ChangeEvent<HTMLInputElement>) => void;
  question?: string;
  changeQuestion?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => void;
}

const ContactForm: FC<Props> = (props) => {
  const {
    contactName,
    phoneNumber,
    question,
    changeContactName,
    changePhoneNumber,
    changeQuestion,
    onSubmit,
  } = props;
  const [contactNameInvalidText, setContactNameInvalidText] =
    useState<string>();
  const [phoneNumberInvalidText, setPhoneNumberInvalidText] =
    useState<string>();
  const [questionInvalidText, setQuestionInvalidText] = useState<string>();

  const submit = useEvent(() => {
    const isContactNameValid = () => {
      const invalidText = emptyValueValidate(contactName);
      setContactNameInvalidText(invalidText);
      return !invalidText;
    };

    const isPhoneNumberValid = () => {
      const invalidText = phoneNumberValidate(phoneNumber);
      setPhoneNumberInvalidText(invalidText);
      return !invalidText;
    };

    const isQuestionValid = () => {
      if (question === undefined) {
        return true;
      }
      const invalidText = emptyValueValidate(question!);
      setQuestionInvalidText(invalidText);
      return !invalidText;
    };

    const isContactNameFieldValid = isContactNameValid();
    const isPhoneNumberFieldValid = isPhoneNumberValid();
    const isQuestionFieldValid = isQuestionValid();

    if (
      isContactNameFieldValid &&
      isPhoneNumberFieldValid &&
      isQuestionFieldValid
    ) {
      onSubmit();
    }
  });

  return (
    <div className="ContactForm">
      <p className="TitleMedium">Заполните форму и мы с вами свяжемся</p>
      <div className="FieldBar">
        <Field label="Фамилия Имя" invalidText={contactNameInvalidText}>
          <input type="text" onChange={changeContactName} value={contactName} />
        </Field>

        <Field label="Номер телефона" invalidText={phoneNumberInvalidText}>
          <input type="text" onChange={changePhoneNumber} value={phoneNumber} />
        </Field>
      </div>
      {question !== undefined && (
        <div className="QuestionField">
          <Field label="Вопрос" invalidText={questionInvalidText}>
            <textarea rows={5} onChange={changeQuestion} value={question}/>
          </Field>
        </div>
      )}
      <FilledButton label="Отправить заявку" onClick={submit} />
    </div>
  );
};

export default ContactForm;
