import { useContext } from "react";
import useContactForm from "./useContactForm";
import useEvent from "./useEvent";
import { AppContext } from "../hoc/ContextProvider";
import {
  FINISH_LOADING,
  HIDE_CONTACT_TO_ORDER_MODALIZE,
  HIDE_SNACKBAR,
  SHOW_SNACKBAR,
  START_LOADING,
} from "../reducer/actions";
import { sendContactToOrder } from "../utils/send-methods";
import { SUCCESS_SEND_CONTACT_FORM } from "../data/snackbar-data";

const useContactToOrderForm = () => {
  const [, dispatch] = useContext(AppContext);
  const {
    contactName,
    changeContactName,
    phoneNumber,
    changePhoneNumber,
    clearFields,
  } = useContactForm();

  const submit = useEvent(() => {
    dispatch({
      type: START_LOADING,
    });
    sendContactToOrder(phoneNumber, contactName)
      .then(() => {
        clearFields();
        dispatch({
          type: HIDE_CONTACT_TO_ORDER_MODALIZE,
        });
        dispatch({ type: HIDE_SNACKBAR });
        dispatch({ type: SHOW_SNACKBAR, payload: SUCCESS_SEND_CONTACT_FORM });
      })
      .catch((error: Error) =>
        dispatch({ type: SHOW_SNACKBAR, payload: error.message })
      )
      .finally(() => dispatch({ type: FINISH_LOADING }));
  });

  return {
    contactName,
    changeContactName,
    phoneNumber,
    changePhoneNumber,
    submit,
  };
};

export default useContactToOrderForm;
