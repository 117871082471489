import React, { FC, PropsWithChildren, useCallback, useState } from "react";

type Props = {
  onClick?: () => void;
  className?: string;
  notUseOpacity?: boolean;
};

const TouchableOpacity: FC<PropsWithChildren<Props>> = (props) => {
  const { children, onClick, className } = props;
  const isUseOpacity = props.notUseOpacity !== undefined ? false  : true;

  const [isTouched, setIsTouched] = useState(false);

  const onTouchStart = useCallback(() => {
    setIsTouched(true);
  }, []);

  const onTouchEnd = useCallback(() => {
    setIsTouched(false);
  }, []);

  const componentClassName = `${className ?? ""} ${
    isTouched && isUseOpacity ? "Touched" : ""
  }`;

  return (
    <button
      className={componentClassName}
      onMouseDown={onTouchStart}
      onMouseUp={onTouchEnd}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TouchableOpacity;
