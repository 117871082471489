import React, { FC, PropsWithChildren } from "react";

type Props = {
  className: string;
  blockTitle: string;
  id?: string;
};

const BlockCardWrapper: FC<PropsWithChildren<Props>> = (props) => {
  const { className, children, blockTitle, id } = props;
  return (
    <div className={className} id={id}>
      <div className="BlockCard">
        <div className="PlatformOffset">
          <div className="BlockCardContent">
            <h1 className="DisplayLarge">{blockTitle}</h1>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCardWrapper;
