import CatalogItemImageModel from "./catalog-item-image-model";
import CatalogItemPriceModel from "./catalog-item-price-model";
import CoatingType from "./coating-type";
import Size from "./size";

interface ConstructorProps {
  title: string;
  image: CatalogItemImageModel;
  coatingType: CoatingType;
  prices: CatalogItemPriceModel[];
  useOnCoating: string;
  usetime: string;
  size?: Size;
  colors?: string;
  composition?: string;
  quantityPerSquareMeter?: number;
  kit?: string;
  isNeedTable?: boolean;
}

class CatalogItemModel {
  title: string;
  thickness: string;
  minPricePerSquareMeter: number;
  image: CatalogItemImageModel;
  coatingType: CoatingType;
  useOnCoating: string;
  usetime: string;
  isNeedTable: Boolean;
  prices: CatalogItemPriceModel[];
  size?: Size;
  colors?: string;
  composition?: string;
  quantityPerSquareMeter?: number;
  kit?: string;

  constructor(props: ConstructorProps) {
    const size = `${props.size?.length}x${props.size?.width}`
    this.title = props.title + (props.size != null ? ` ${size}` : "");
    this.thickness = `${props.prices.map((e) => e.thickness).join(", ")} мм`;
    this.minPricePerSquareMeter = 0;
    for (const priceModel of props.prices) {
      if (this.minPricePerSquareMeter == 0) {
        this.minPricePerSquareMeter = priceModel.minPricePerSquareMeter;
      }
      if (this.minPricePerSquareMeter > priceModel.minPricePerSquareMeter) {
        this.minPricePerSquareMeter = priceModel.minPricePerSquareMeter;
      }
    }
    this.image = props.image;
    this.coatingType = props.coatingType;
    this.useOnCoating = props.useOnCoating;
    this.usetime = props.usetime;
    this.isNeedTable = props.isNeedTable ?? true;
    this.prices = props.prices;
    this.size = props.size;
    this.colors = props.colors;
    this.composition = props.composition;
    this.quantityPerSquareMeter = props.quantityPerSquareMeter;
    this.kit = props.kit;
  }
}

export default CatalogItemModel;
