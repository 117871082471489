import { FC } from "react";
import useQuestionForm from "../../hooks/useQuestionForm";
import ContactForm from "../ContactForm";

const QuestionForm: FC = () => {
  const {
    contactName,
    changeContactName,
    phoneNumber,
    changePhoneNumber,
    question,
    changeQuestion,
    submit,
  } = useQuestionForm();

  return (
    <ContactForm
      contactName={contactName}
      changeContactName={changeContactName}
      phoneNumber={phoneNumber}
      changePhoneNumber={changePhoneNumber}
      question={question}
      changeQuestion={changeQuestion}
      onSubmit={submit}
    />
  );
};

export default QuestionForm;
