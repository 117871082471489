import { FC, useCallback, useContext } from "react";
import { HIDE_CONTACT_TO_ORDER_MODALIZE } from "../../reducer/actions";
import ModalWrapper from "./ModalWrapper";
import { AppContext } from "../../hoc/ContextProvider";
import useContactToOrderForm from "../../hooks/useContactToOrderForm";
import ContactForm from "../ContactForm";

const ContactToOrderModalize: FC = () => {
  const [state, dispatch] = useContext(AppContext);

  const onClose = useCallback(() => {
    dispatch({
      type: HIDE_CONTACT_TO_ORDER_MODALIZE,
    });
  }, [dispatch]);

  return (
    <>
      {state.isContactToOrderModalizeShowed && (
        <ModalWrapper onClose={onClose}>
          <h3 className="DisplaySmall">Заявка</h3>
          <ContactToOrderForm />
        </ModalWrapper>
      )}
    </>
  );
};

export default ContactToOrderModalize;

const ContactToOrderForm: FC = () => {
  const {
    contactName,
    changeContactName,
    phoneNumber,
    changePhoneNumber,
    submit,
  } = useContactToOrderForm();

  return (
    <ContactForm
      contactName={contactName}
      changeContactName={changeContactName}
      phoneNumber={phoneNumber}
      changePhoneNumber={changePhoneNumber}
      onSubmit={submit}
    />
  );
};
