import { FC } from "react";
import BlockCardWrapper from "../BlockCardWrapper";
import QuestionForm from "./QuestionForm";

const QuestionBlock: FC = () => {
  return (
    <BlockCardWrapper
      className="QuestionBlock"
      blockTitle="Не нашли вопрос на свой вопрос?"
    >
      <QuestionForm />
    </BlockCardWrapper>
  );
};

export default QuestionBlock;
