import { PHONE_NUMBER_FORMAT } from "../hooks/useContactForm";

const VALIDATOR_DATA = {
  empty: "Заполните поле",
  trimmedValueIsEmpty: "Значение не может состоять из пробелов",
  notBeZero: "Не может быть нулем",
};

export const phoneNumberValidate = (value: string): string | undefined => {
  if (value.length != PHONE_NUMBER_FORMAT.mask.length) {
    return VALIDATOR_DATA.empty;
  }
};

export const emptyValueValidate = (value: string): string | undefined => {
  if (value === "") {
    return VALIDATOR_DATA.empty;
  } else if (value.trim() === "") {
    return VALIDATOR_DATA.trimmedValueIsEmpty;
  }
};

export const numberValidate = (value: string): string | undefined => {
  if (value === "") {
    return VALIDATOR_DATA.empty;
  } else if (+value === 0) {
    return VALIDATOR_DATA.notBeZero;
  }
};
