import { FC, useContext, useEffect } from "react";
import { AppContext } from "../hoc/ContextProvider";
import { HIDE_SNACKBAR } from "../reducer/actions";

const Snackbar: FC = () => {
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    if (state.snackbarText) {
      const timer = setTimeout(() => {
        dispatch({
          type: HIDE_SNACKBAR,
        });
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [state.snackbarText]);

  return (
    <>
      {!!state.snackbarText && (
        <div className="SnackbarContainer">
          <div className="PlatformOffset">
            <div className="Snackbar">
              <p className="HeadlineSmall">{state.snackbarText}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Snackbar;
