import { useState, useCallback } from "react";

const CONTACT_NAME_REGEXP = /^[а-яА-Я\s]{0,}$/;

export const PHONE_NUMBER_FORMAT = {
  placeholderChar: "*",
  code: "+7",
  mask: "+7(***)***-**-**",
};

const useContactForm = () => {
  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(PHONE_NUMBER_FORMAT.code);
  const [question, setQuestion] = useState("");

  const changeContactName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      var newValue: string = event.target.value;

      if (CONTACT_NAME_REGEXP.test(newValue)) {
        setContactName(newValue);
      }
    },
    []
  );

  const changePhoneNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue: string = event.target.value;
      var value = PHONE_NUMBER_FORMAT.code;
      const length =
        newValue.length > PHONE_NUMBER_FORMAT.mask.length
          ? PHONE_NUMBER_FORMAT.mask.length
          : newValue.length;
      for (let i = 2; i < length; i++) {
        if (i === length - 1 && Number.isNaN(Number.parseInt(newValue[i]))) {
          continue;
        } else if (PHONE_NUMBER_FORMAT.mask[i] === newValue[i]) {
          value += PHONE_NUMBER_FORMAT.mask[i];
        } else if (
          PHONE_NUMBER_FORMAT.mask[i] === PHONE_NUMBER_FORMAT.placeholderChar
        ) {
          value += newValue[i];
        } else {
          value += PHONE_NUMBER_FORMAT.mask[i];
          value += newValue[i];
        }
      }
      setPhoneNumber(value);
    },
    []
  );

  const changeQuestion = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue: string = event.target.value;
      setQuestion && setQuestion(newValue);
    },
    []
  );

  const clearFields = useCallback(()=>{
    setContactName("");
    setPhoneNumber(PHONE_NUMBER_FORMAT.code);
    setQuestion("");
  },[])

  return {
    contactName,
    changeContactName,
    phoneNumber,
    changePhoneNumber,
    question,
    changeQuestion,
    clearFields
  };
};

export default useContactForm;
