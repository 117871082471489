import { FC } from "react";
import SectionButton from "../SectionButton";

interface Props {
  onPress?: () => void;
}

export const CatalogSectionButton: FC<Props> = ({ onPress }) => {
  return <SectionButton label="Каталог" id="каталог" onPress={onPress} />;
};

export const HowUseSectionButton: FC<Props> = ({ onPress }) => {
  return <SectionButton label="Применение" id="применение" onPress={onPress} />;
};

export const CalculatorSectionButton: FC<Props> = ({ onPress }) => {
  return (
    <SectionButton label="Калькулятор" id="калькулятор" onPress={onPress} />
  );
};

export const ContactsSectionButton: FC<Props> = ({ onPress }) => {
  return <SectionButton label="Контакты" id="контакты" onPress={onPress} />;
};
