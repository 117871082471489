import React, {
  useReducer,
  useMemo,
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
} from "react";
import { State, initialState } from "../reducer/state";
import stateReducer from "../reducer/stateReducer";
import { Action } from "../reducer/actions";

export interface Context {
  state: State;
  dispatch: Dispatch<Action>;
}

const AppContext = createContext<[State, Dispatch<Action>]>([
  initialState,
  () => {},
]);

const AppProvider: FC<PropsWithChildren<{}>> = (props) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const store = useMemo<[State, Dispatch<Action>]>(
    () => [state, dispatch],
    [state]
  );

  return (
    <AppContext.Provider value={store}>{props.children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
