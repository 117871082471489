import React, { FC, useCallback, useContext } from "react";
import { AppContext } from "../../hoc/ContextProvider";
import FilledButton from "../FilledButton";
import TilesImage from "../../../assets/images/tiles.png";
import SeeMoreText from "../../../assets/images/see-more.svg";
import TouchableOpacity from "../TouchableOpacity";
import { HashLink } from "react-router-hash-link";
import { SHOW_CONTACT_TO_ORDER_MODALIZE } from "../../reducer/actions";

const StartBlock: FC = () => {
  const [state] = useContext(AppContext);
  return (
    <div className="StartBlock">
      <div className="PlatformOffset">
        <div className="StartBlockContent">
          {!state.isMobile ? (
            <DesktopStartBlockContent />
          ) : (
            <MobileStartBlockContent />
          )}
        </div>
      </div>
    </div>
  );
};

export default StartBlock;

const DesktopStartBlockContent: FC = () => {
  return (
    <>
      <div className="LeftLine" />
      <div>
        <h2 className="DisplayMedium">
          Качественное покрытие из резиновой крошки
        </h2>
        <p className="TitleMedium">
          Для детских площадок, фитнес-клубов, стадионов
        </p>
        <div className="ButtonBar">
          <ShowCatalogButton />
          <RequestToOrderButton />
        </div>
        <p className="LabelMedium">Осуществляем доставку по всей России</p>
      </div>
      <RightLineView />
    </>
  );
};

const MobileStartBlockContent: FC = () => {
  return (
    <>
      <h2 className="DisplayMedium">
        Качественное покрытие из резиновой крошки
      </h2>
      <p className="TitleMedium">
        Для детских площадок, фитнес-клубов, стадионов
      </p>
      <p className="LabelMedium">Осуществляем доставку по всей России</p>
      <div className="TilesImagesView">
        <div className="LeftLine" />
        <img src={TilesImage} height={250} />
        <RightLineView />
      </div>
      <ShowCatalogButton />
      <RequestToOrderButton />
    </>
  );
};

const ShowCatalogButton: FC = () => {
  return (
    <HashLink smooth to={`#каталог`}>
      <FilledButton label="Посмотреть товар" />
    </HashLink>
  );
};

const RequestToOrderButton: FC = () => {
  const [_, dispatch] = useContext(AppContext);

  const showContactToOrderModalize = useCallback(() => {
    dispatch({
      type: SHOW_CONTACT_TO_ORDER_MODALIZE,
    });
  }, []);

  return (
    <FilledButton
      label="Оформить заявку"
      isSecondary
      onClick={showContactToOrderModalize}
    />
  );
};

const RightLineView: FC = () => {
  return (
    <div className="RightLineView">
      <TouchableOpacity>
        <HashLink smooth to={`#каталог`}>
          <img src={SeeMoreText} />
        </HashLink>
      </TouchableOpacity>
      <div className="RightLine" />
    </div>
  );
};
