import React, { FC } from "react";
import TouchableOpacity from "./TouchableOpacity";
import { HashLink } from "react-router-hash-link";

interface Props {
  label: string;
  id: string;
  onPress?: () => void;
}

const SectionButton: FC<Props> = ({ label, id, onPress }) => {
  return (
    <TouchableOpacity className="SectionButton" onClick={onPress}>
      <HashLink smooth to={`#${id}`}>
        <p className="TitleMedium">{label}</p>
      </HashLink>
    </TouchableOpacity>
  );
};

export default SectionButton;
