import APP_SETTINGS from "../data/app-settings";

const sendEmail = async (title: string, text: string) => {
  const jsonData = JSON.stringify({
    recipient: APP_SETTINGS.emailAddress,
    subject: title,
    body: text,
  });
  const response = await fetch(
    "https://email-resender.onrender.com/sendMail/",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    }
  );

  if (!response.ok) {
    throw Error("Не удалось отправить заявку. Попробуйте позже");
  }
};

export default sendEmail;
