import React, { FC, useCallback, useContext } from "react";
import TouchableOpacity from "../TouchableOpacity";
import { AppContext } from "../../hoc/ContextProvider";
import { SHOW_MOBILE_MENU } from "../../reducer/actions";

const MenuButton: FC = () => {
  const [,dispatch] = useContext(AppContext);

  const openMenu = useCallback(() => {
    dispatch({
      type: SHOW_MOBILE_MENU
    })
  },[dispatch])

  return (
    <TouchableOpacity className="MenuButton" onClick={openMenu}>
      <div className="Menu" />
    </TouchableOpacity>
  );
};

export default MenuButton;
