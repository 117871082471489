import { FC, PropsWithChildren, useContext } from "react";
import { AppContext } from "../../hoc/ContextProvider";
import { CATALOG_ITEMS } from "../../data/catalog-items";

const PriceTable: FC<PropsWithChildren<{}>> = () => {
  return (
    <table>
      <thead>
        <tr>
          <th className="TitleMedium">Размер, мм</th>
          <th className="TitleMedium">Цена, за м²</th>
        </tr>
      </thead>

      <PriceTableItems />
    </table>
  );
};

export default PriceTable;

const PriceTableItems: FC = () => {
  const [state] = useContext(AppContext);
  const catalogItem = state.catalogItem;
  return (
    <tbody>
      {catalogItem?.prices.map((v) => {
        const size = `${catalogItem?.size?.length}x${catalogItem?.size?.length}x${v.thickness}`;
        return (
          <tr key={size}>
            <td className="BodyMedium">{size}</td>
            <td className="BodyMedium">{`от ${v.minPricePerSquareMeter} руб`}</td>
          </tr>
        );
      })}
    </tbody>
  );
};
