import { FC, useContext } from "react";
import { AppContext } from "../../hoc/ContextProvider";
import Logo from "../Logo";
import {
  CatalogSectionButton,
  HowUseSectionButton,
  CalculatorSectionButton,
  ContactsSectionButton,
} from "../header/SectionButtons";

const Footer: FC = () => {
  const [state] = useContext(AppContext);

  return (
    <footer id="контакты">
      <div className="BlockCard">
        <div className="FooterContent PlatformOffset">
          {!state.isMobile ? (
            <>
              <CatalogSectionButton />
              <HowUseSectionButton />
              <Logo isLightLogo />
              <CalculatorSectionButton />
              <ContactsSectionButton />
            </>
          ) : (
            <Logo isLightLogo />
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
