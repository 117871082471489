import { FC, useCallback, useContext } from "react";
import { AppContext } from "../../hoc/ContextProvider";
import { HIDE_MOBILE_MENU } from "../../reducer/actions";
import TouchableOpacity from "../TouchableOpacity";
import CloseButton from "./CloseButton";
import {
  CalculatorSectionButton,
  CatalogSectionButton,
  ContactsSectionButton,
  HowUseSectionButton,
} from "./SectionButtons";
import Logo from "../Logo";

const MobileMenu: FC = () => {
  const [state, dispatch] = useContext(AppContext);

  const close = useCallback(() => {
    dispatch({
      type: HIDE_MOBILE_MENU,
    });
  }, [dispatch]);

  return (
    <>
      {state.isMobileMenuShowed && (
        <div className="MobileMenuContainer">
          <TouchableOpacity
            onClick={close}
            notUseOpacity
            className="MobileMenuBackground"
          />
          <div className="MobileMenu">
            <header>
              <div className="HeaderContent PlatformOffset">
                <Logo onPress={close} />
                <CloseButton onPress={close} />
              </div>
            </header>

            <div className="SectionsView ">
              <CatalogSectionButton onPress={close} />
              <HowUseSectionButton onPress={close} />
              <CalculatorSectionButton onPress={close} />
              <ContactsSectionButton onPress={close} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileMenu;
